import { postData } from "@/__main__/get-data.mjs";
import noopModel from "@/data-models/no-op.mjs";
import type { MatchMutationT } from "@/game-fortnite/models/match-mutation.mjs";
import * as API from "@/game-fortnite/utils/api.mjs";

async function matchCreateQuery(match: MatchMutationT) {
  await postData(API.createMatch({ match }), noopModel, undefined);
}

export default matchCreateQuery;
